.ant-tag:not(.ant-picker-calendar-date-content .ant-tag) {
  padding: 2px 7px;
  font-weight: 400;
  font-size: 15px;
}

.ant-descriptions-small .ant-descriptions-row > td {
  padding-bottom: 0;
}

.ant-card-meta-description {
  margin-bottom: 4px;
}

.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 2px;
}

.Summary .ant-card-head-title {
  font-size: 1.25em;
  font-weight: 600;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  font-size: inherit;
  color: currentColor;
}

.ant-carousel .slick-prev {
  left: 6px;
  z-index: 1;
}

.ant-carousel .slick-next {
  right: 6px;
  z-index: 1;
}
